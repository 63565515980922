import React from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import { Utils } from "utils";

// Páginas
import Menu from "screens/menu";
import Comunicacao from "screens/home";
import Chatbot from "screens/chatbot";
import AddMessage from "screens/addMessage";
import Messages from "screens/messages";
import MessageSendSuccess from "screens/messageSendSuccess";
import TermsOfUse from "screens/termsOfUse";
import Documents from "screens/documents";
import Holerite from "screens/holerite";
import InformeRendimento from "screens/informeRendimento";
import Ead from "screens/ead";
import Logout from "screens/logout";
import Page404 from "screens/404";
import PendingEmailConfimation from "screens/pendingEmailConfirmation";
import ChangeEmail from "screens/changeEmail/ChangeEmail";
import HeaderPage from "components/headerPage";

const Private = () => {
  return (
    <React.Fragment>
      <HeaderPage />
      <Switch>
        <Route exact path={Utils.setPrefix("/")} component={Menu} />
        <Route
          exact
          path={Utils.setPrefix("/comunicacao-interna")}
          component={Comunicacao}
        />
        <Route
          exact
          path={Utils.setPrefix("/adicionar-mensagem")}
          component={AddMessage}
        />
        <Route
          exact
          path={Utils.setPrefix("/mensagem-enviada-com-sucesso")}
          component={MessageSendSuccess}
        />
        <Route
          exact
          path={Utils.setPrefix("/mensagem/:id")}
          component={Messages}
        />
        <Route
          exact
          path={Utils.setPrefix("/termos-de-uso")}
          component={TermsOfUse}
        />
        <Route
          exact
          path={Utils.setPrefix("/documentos")}
          component={Documents}
        />
        <Route
          exact
          path={Utils.setPrefix("/change-email")}
          component={ChangeEmail}
        />
        <Route
          exact
          path={Utils.setPrefix("/pending-email-confirmation/")}
          component={PendingEmailConfimation}
        />
        <Route
          exact
          path={Utils.setPrefix("/informe-rendimentos")}
          component={InformeRendimento}
        />
        <Route
          exact
          path={Utils.setPrefix("/holerites")}
          component={Holerite}
        />
        <Route exact path={Utils.setPrefix("/ead")} component={Ead} />
        <Route exact path={Utils.setPrefix("/chat")} component={Chatbot} />
        <Route exact path={Utils.setPrefix("/logout")} component={Logout} />
        <Route component={Page404} />
      </Switch>
    </React.Fragment>
  );
};

export default withRouter(Private);
