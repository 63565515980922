import { useState, useEffect } from "react";
import { useAuth } from "contexts/Auth.contexts";
import Api from "service/api";
import Store from "store";

const baseUrl = window.location.host;
const protocol = window.location.protocol;

const useMenu = () => {
    const { notificationIncoming, profile } = useAuth();
    const [notificationComunication, setNotificationComunication] = useState(
        []
    );
    const isFired = Store.get("fired");

    const getNotification = () => {
        Api.get("notificacao").then((response) => {
            const listagem = response.data.listagem;
            const items = listagem.map((iten) => {
                return {
                    id: iten.noti_id_notificacao,
                    comunicacaoId: iten.noti_data.id_comunicacao,
                    url: `${protocol}//${baseUrl}/mensagem/${iten.noti_data.id_comunicacao}`,
                };
            });

            setNotificationComunication(items);
        });
    };

    useEffect(() => {
        getNotification();
    }, [notificationIncoming]);

    console.log(profile);

    return { notificationComunication, isFired, profile };
};

export default useMenu;
