import React, { useCallback } from "react";
import moment from "moment";
import Loading from "components/loading";
import ButtonCustom from "components/buttonCustom";

import useChatbox from "./useChatbot";

import { Main, Title, TitleIcon, Inner, BtnGroup, IconCustom } from "./Styled";

import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    TextField,
    IconButton,
    Container,
    Input,
    CardMedia,
} from "@mui/material";

import ComunImg from "./images/talk.png";
import Axios from "axios";
// import SendIcon from "@mui/icons-material/Send";

const Home = () => {
    const {
        transitionIn,
        loadingPage,
        translate,
        Utils,
        handleSendMessage,
        handleFileAttach,
        idSession,
        messages,
        newMessage,
        setNewMessage,
        initChat,
        handleInitChat,
        tokenChat,
    } = useChatbox();

    const parseStringWithLink = (text) => {
        const regex = /\[(.*?)\]\((.*?)\)/g;
        const parts = [];
        let lastIndex = 0;
        let match;

        const handleDownload = async (url) => {
            try {
                const response = await Axios.get(url, {
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${tokenChat}`,
                    },
                });
                const blob = new Blob([response.data]);
                const blobUrl = window.URL.createObjectURL(blob);

                // Cria um link temporário para download
                const a = document.createElement("a");
                a.href = blobUrl;
                a.download = "arquivo-download.pdf"; // Nome do arquivo para download
                a.click();

                // Libera o objeto blob
                window.URL.revokeObjectURL(blobUrl);
            } catch (error) {
                console.error("Erro ao baixar o arquivo:", error);
            }
        };

        while ((match = regex.exec(text)) !== null) {
            // Adiciona o texto antes do link
            if (match.index > lastIndex) {
                parts.push(text.slice(lastIndex, match.index));
            }
            // console.log(match[2]);
            let dadis = match;
            parts.push(
                <button
                    key={match.index}
                    onClick={() => handleDownload(dadis[2])}
                    // onClick={() => {
                    //     console.log(dadis);
                    // }}
                    // style={{
                    //     background: "none",
                    //     border: "none",
                    //     color: "blue",
                    //     textDecoration: "underline",
                    //     cursor: "pointer",
                    //     padding: 0,
                    // }}
                >
                    {match[1]}
                </button>
            );
            lastIndex = regex.lastIndex;
        }

        // Adiciona o texto restante
        if (lastIndex < text.length) {
            parts.push(text.slice(lastIndex));
        }

        return parts;
    };

    const parseStringWithLinkV2 = useCallback(
        async (responseString) => {
            responseString = responseString.replace("[aqui](", "");
            // Regex para detectar um link (URLs simples: http ou https)
            const urlRegex = /(https?:\/\/[^\s]+)/g;

            // Substituir a URL por um elemento <a>
            const parts = responseString.split(urlRegex);
            console.log(parts);
            // Renderiza o texto com o link como um elemento <a>
            const jsxContent = parts.map((part, index) => {
                if (urlRegex.test(part)) {
                    // Função para baixar o arquivo usando axios
                    const handleDownload = async (e) => {
                        e.preventDefault();
                        try {
                            let urlParse = part
                                .replace("(", "")
                                .replace(")", "");
                            const response = await Axios.get(urlParse, {
                                responseType: "blob",
                                headers: {
                                    Authorization: `Bearer ${tokenChat}`,
                                },
                            });

                            // Criar um URL Blob para o arquivo baixado
                            const fileURL = window.URL.createObjectURL(
                                new Blob([response.data])
                            );
                            // const fileName = part.split("urlParse").pop(); // Extrai o nome do arquivo da URL
                            const fileName = "holerite.pdf";
                            // Criar temporariamente um elemento <a> para acionar o download
                            const a = document.createElement("a");
                            a.href = fileURL;
                            a.setAttribute("download", fileName);
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        } catch (error) {
                            console.error("Erro ao baixar o arquivo:", error);
                        }
                    };

                    // Retorna o elemento <a> para a URL
                    return (
                        <a href="#" key={index} onClick={handleDownload}>
                            [aqui]
                        </a>
                    );
                }
                return part;
            });

            return responseString;
        },
        [tokenChat]
    );

    if (loadingPage) {
        return <Loading />;
    }

    if (!initChat) {
        return (
            <React.Fragment>
                <Main>
                    <Container>
                        <Title transition={transitionIn}>
                            <TitleIcon src={ComunImg} />
                            Chatbot
                        </Title>
                        <Inner transition={transitionIn}>
                            <BtnGroup transition={transitionIn}>
                                <ButtonCustom
                                    color="default"
                                    type="button"
                                    size="large"
                                    className="btn-green"
                                    // startIcon={<IconCustom className="icon icon-plus" />}
                                    onClick={handleInitChat}
                                    fullWidth
                                >
                                    INICIAR CHAT
                                </ButtonCustom>
                            </BtnGroup>
                        </Inner>
                    </Container>
                </Main>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Main>
                <Container>
                    <Title transition={transitionIn}>
                        <TitleIcon src={ComunImg} />
                        Chatbot
                    </Title>

                    <Inner transition={transitionIn}>
                        <Container
                            sx={{ flexGrow: 1, overflowY: "auto", mt: 2 }}
                        >
                            <List>
                                {messages.map((msg, index) => (
                                    <ListItem
                                        key={index}
                                        sx={{
                                            display: "flex",
                                            justifyContent:
                                                msg.remetente === "Você"
                                                    ? "flex-end"
                                                    : "flex-start",
                                            alignItems: "flex-start",
                                        }}
                                    >
                                        {msg.remetente !== "Você" && (
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{ bgcolor: "#395e95" }}
                                                >
                                                    {msg.avatar}
                                                </Avatar>
                                            </ListItemAvatar>
                                        )}

                                        <Box
                                            sx={{
                                                maxWidth: "70%",
                                                borderRadius: 2,
                                                px: 2,
                                                py: 1,
                                                bgcolor:
                                                    msg.remetente === "Você"
                                                        ? "#009e64"
                                                        : "#395e95",
                                                color:
                                                    msg.remetente === "Você"
                                                        ? "#fff"
                                                        : "#FFF",
                                                textAlign:
                                                    msg.remetente === "Você"
                                                        ? "right"
                                                        : "left",
                                                boxShadow: 1,
                                            }}
                                        >
                                            <Typography
                                                variant="body2"
                                                fontWeight="bold"
                                            >
                                                {msg.remetente}
                                            </Typography>
                                            {msg.image ? (
                                                // Exibe thumbnail para imagens
                                                <CardMedia
                                                    component="img"
                                                    image={msg.image}
                                                    alt="Imagem anexada"
                                                    sx={{
                                                        borderRadius: 2,
                                                        maxWidth: "100%",
                                                        maxHeight: 150,
                                                    }}
                                                />
                                            ) : (
                                                <Typography>
                                                    {parseStringWithLink(
                                                        msg.texto
                                                    )}
                                                </Typography>
                                            )}
                                        </Box>

                                        {msg.remetente === "Você" && (
                                            <ListItemAvatar
                                                sx={{ paddingLeft: "13px" }}
                                            >
                                                <Avatar
                                                    sx={{ bgcolor: "#009e64" }}
                                                >
                                                    {msg.avatar}
                                                </Avatar>
                                            </ListItemAvatar>
                                        )}
                                    </ListItem>
                                ))}
                            </List>

                            <Box
                                sx={{
                                    display: "flex",
                                    p: 2,
                                    borderTop: "1px solid #DDD",
                                    // backgroundColor: "#e6e6e6",
                                    boxShadow:
                                        "0px 2px 5px 0px rgba(0,0,0,0.1);",
                                    marginBottom: "16px",
                                }}
                            >
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Digite sua mensagem..."
                                    value={newMessage}
                                    onChange={(e) =>
                                        setNewMessage(e.target.value)
                                    }
                                    onKeyPress={(e) =>
                                        e.key === "Enter" && handleSendMessage()
                                    }
                                />

                                <IconButton
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    component="label"
                                    sx={{ ml: 1 }}
                                >
                                    <IconCustom
                                        className="icon icon-attached"
                                        color="black"
                                    />
                                    <Input
                                        type="file"
                                        hidden
                                        onChange={handleFileAttach}
                                        style={{ display: "none" }}
                                    />
                                </IconButton>

                                <IconButton
                                    color="primary"
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    onClick={handleSendMessage}
                                    sx={{ ml: 1 }}
                                >
                                    <IconCustom
                                        className="icon icon-send"
                                        color="black"
                                    />
                                </IconButton>
                            </Box>
                        </Container>
                    </Inner>
                </Container>
            </Main>
        </React.Fragment>
    );
};

export default Home;
