import { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Utils } from "utils";
import mqtt from "mqtt";

import Api from "service/api";
import Store from "store";
import { useAuth } from "../../contexts/Auth.contexts";
import Axios from "axios";

const data = [];

const useChartbot = () => {
    const [initChat, setInitChat] = useState(false);
    const [tokenChat, setTokenChat] = useState("");
    const [idSession, setIdSession] = useState(0);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [letraInicial, setLetraInicial] = useState("");

    const [loadingPage, setLoadingPage] = useState(false);
    const [transitionIn, setTransitionIn] = useState(true);
    const { notificationIncoming, profile } = useAuth();
    const history = useHistory();
    const { t: translate } = useTranslation();

    const currentLang = window.localStorage.i18nextLng;

    document.title = translate("home.head.title");

    const apiCHAT = Axios.create({
        baseURL:
            process.env.NODE_ENV != "development"
                ? "https://api-chatbot.sagris.com.br/"
                : `http://localhost:8000/`,
    });

    // Interceptar respostas com erro
    apiCHAT.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response && error.response.status === 401) {
                Store.remove("token_chat");
                Store.remove("id_session_chat");
                setInitChat(false);
                setTokenChat(null);
                setIdSession(null);
            }
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        window.scrollTo(0, 0);

        setLetraInicial(profile.pess_nome[0] ?? "J");

        let tokenChatStore = Store.get("token_chat");
        let sessionShatStore = Store.get("id_session_chat");
        if (typeof tokenChatStore !== "undefined") {
            apiCHAT
                .get("is_logged", {
                    headers: {
                        Authorization: `Bearer ${tokenChatStore}`,
                    },
                })
                .then((resp) => {
                    setInitChat(true);
                    setTokenChat(tokenChatStore);
                    setIdSession(sessionShatStore);
                })
                .catch((resp) => {
                    console.log("ERROR");
                });
        }
    }, [notificationIncoming]);

    const urlChat = (path) => {
        let url =
            process.env.NODE_ENV != "development"
                ? "https://api-chatbot.sagris.com.br/"
                : `http://localhost:8000/`;
        return `${url}${path}`;
    };

    const handleInitChat = useCallback(async () => {
        let url = urlChat("token");
        let dataLogin = Store.get("logins")
            ? JSON.parse(Store.get("logins"))
            : {};

        setLoadingPage(true);
        apiCHAT
            .post(
                url,
                new URLSearchParams({
                    username: dataLogin.login,
                    password: dataLogin.password,
                }),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .then((response) => {
                let chatToken = response.data.access_token;
                Store.set("token_chat", chatToken);
                setInitChat(true);
                setTokenChat(chatToken);
                getSessionID(chatToken);
            })
            .catch((response) => {
                console.log("HEHEHEHEHEHEHE");
            })
            .finally(() => {
                setLoadingPage(false);
            });
    }, []);

    const getSessionID = useCallback(async (chatToken) => {
        let url = urlChat("start_chat");
        apiCHAT
            .post(
                url,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${chatToken}`,
                    },
                }
            )
            .then((responseInit) => {
                setIdSession(responseInit.data.session_id);
                Store.set("id_session_chat", responseInit.data.session_id);
            });
    }, []);

    const handleSendMessage = useCallback(async () => {
        // console.log(idSession);
        if (newMessage.trim() === "") return;

        setMessages([
            ...messages,
            { remetente: "Você", texto: newMessage, avatar: letraInicial },
        ]);
        setNewMessage("");
        let url = urlChat("chat");
        try {
            const response = await apiCHAT.post(
                url,
                { mensagem: newMessage.trim(), session_id: idSession },
                {
                    headers: {
                        Authorization: `Bearer ${tokenChat}`,
                    },
                }
            );
            const respostaBot = {
                remetente: "BOT",
                texto: response.data.resposta,
                avatar: "B",
            };
            setMessages((hist) => [...hist, respostaBot]);
        } catch (err) {
            console.error(err);
        }
    }, [idSession, messages, newMessage, tokenChat, letraInicial]);

    const handleFileAttach = useCallback(
        async (event) => {
            const file = event.target.files[0];
            if (file) {
                const fileReader = new FileReader();
                fileReader.onload = () => {
                    const formData = new FormData();
                    formData.append("file", file);
                    formData.append("session_id", idSession);

                    // Substitua pela URL do seu servidor
                    const response = apiCHAT.post(
                        "upload-attachment",
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${tokenChat}`,
                            },
                        }
                    );

                    const fileType = file.type.split("/")[0];
                    if (fileType === "image") {
                        let respostaBot = {
                            remetente: "Você",
                            texto: null,
                            avatar: letraInicial,
                            image: fileReader.result, // Base64 da imagem
                            fileName: null,
                        };
                        setMessages((hist) => [...hist, respostaBot]);
                    } else {
                        let respostaBot = {
                            remetente: "Você",
                            texto: `📎 Arquivo anexado: ${file.name}`,
                            avatar: letraInicial,
                            image: null,
                            fileName: file.name,
                        };
                        setMessages((hist) => [...hist, respostaBot]);
                    }
                };
                fileReader.readAsDataURL(file);
            }
        },
        [idSession, tokenChat, letraInicial]
    );

    return {
        transitionIn,
        loadingPage,
        currentLang,
        handleSendMessage,
        handleFileAttach,
        idSession,
        messages,
        translate,
        Utils,
        newMessage,
        handleInitChat,
        initChat,
        setNewMessage,
        tokenChat,
    };
};

export default useChartbot;
